import { 
  createServerClient, 
  createBrowserClient, 
  parseCookieHeader, 
  serializeCookieHeader 
} from '@supabase/ssr';
import { createClient as createSupabaseClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseServiceRoleKey = process.env.REACT_APP_SUPABASE_SERVICE_ROLE_KEY;

if (!supabaseUrl || !supabaseAnonKey || !supabaseServiceRoleKey) {
  throw new Error('Supabase URL, Anon Key, and Service Role Key must be provided as environment variables');
}

const isDevelopment = process.env.NODE_ENV === 'development';

// Create server-side Supabase client
export const createServerSupabaseClient = (context) => {
  return createServerClient(supabaseUrl, supabaseAnonKey, {
    cookies: {
      get: (key) => {
        const cookies = parseCookieHeader(context.req.headers.cookie ?? '');
        return cookies[key];
      },
      set: (key, value, options) => {
        const cookie = serializeCookieHeader(key, value, options);
        context.res.appendHeader('Set-Cookie', cookie);
      },
      remove: (key, options) => {
        const cookie = serializeCookieHeader(key, '', { ...options, maxAge: -1 });
        context.res.appendHeader('Set-Cookie', cookie);
      },
    },
  });
};

// Create client-side Supabase client
export const createBrowserSupabaseClient = () => {
  if (isDevelopment) {
    console.debug('Creating Supabase client with URL:', supabaseUrl);
    console.debug('Anon Key (first 10 characters):', supabaseAnonKey.substring(0, 10) + '...');
  }

  return createBrowserClient(supabaseUrl, supabaseAnonKey, {
    auth: {
      flowType: 'pkce',
      detectSessionInUrl: true,
      persistSession: true,
      autoRefreshToken: true,
    },
  });
};

// Create Supabase client with service role
export const createServiceRoleClient = () => {
  return createSupabaseClient(supabaseUrl, supabaseServiceRoleKey, {
    auth: {
      autoRefreshToken: false,
      persistSession: false,
    },
  });
};

// Create appropriate Supabase client based on the environment
export const createClient = (context) => {
  return typeof window === 'undefined'
    ? createServerSupabaseClient(context)
    : createBrowserSupabaseClient();
};

/**
 * Get or set cached data using the database caching mechanism.
 * 
 * @param {string} key - Unique identifier for the cached data.
 * @param {string} valueFunction - SQL query or function to execute if cache is missing or expired.
 * @param {string} expiresIn - Cache expiration time (e.g., '15 minutes', '1 hour', '1 day').
 * @param {object} context - Request context (required for server-side operations).
 * @returns {Promise<any>} - The cached or freshly fetched data.
 * 
 * Usage example:
 * const userCollections = await getCachedData(
 *   `user_collections_${userId}`,
 *   `SELECT * FROM user_collections WHERE user_id = '${userId}'`,
 *   '15 minutes',
 *   context
 * );
 */
export const getCachedData = async (key, valueFunction, expiresIn, context) => {
  const supabase = createClient(context);
  if (isDevelopment) {
    console.debug('Fetching cached data with key:', key);
    console.debug('Value function:', valueFunction);
    console.debug('Expires in:', expiresIn);
  }
  const { data, error } = await supabase.rpc('get_or_set_cache', {
    p_key: key,
    p_value_function: valueFunction,
    p_expires_in: expiresIn
  });

  if (error) {
    console.error('Error fetching cached data:', error);
    throw error;
  }
  if (isDevelopment) {
    console.debug('Cached data fetched successfully:', data);
  }
  return data;
};

// Set up authentication listener (client-side only)
export const setupAuthListener = (callback) => {
  if (typeof window !== 'undefined') {
    const supabase = createBrowserSupabaseClient();
    const subscription = supabase.auth.onAuthStateChange(callback);

    return {
      data: {
        subscription: {
          unsubscribe: () => {
            subscription.unsubscribe();
          },
        },
      },
    };
  }
};

// Fetch the current user
export const getUser = async (context) => {
  try {
    const supabase = createClient(context);
    if (isDevelopment) console.debug('Fetching current user');
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) throw error;
    if (isDevelopment) console.debug('Current user fetched successfully:', user);
    return user;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

// Upload profile picture and get public URL
export const uploadProfilePicture = async (file, userId, context) => {
  const supabase = createClient(context);
  const fileExt = file.name.split('.').pop();
  const fileName = `${userId}/${Math.random()}.${fileExt}`;
  const filePath = `profile-pictures/${fileName}`;

  if (isDevelopment) console.debug('Uploading profile picture:', filePath);
  const { error: uploadError } = await supabase.storage.from('avatars').upload(filePath, file);
  if (uploadError) throw uploadError;

  if (isDevelopment) console.debug('Fetching public URL for uploaded picture');
  const { data: { publicUrl }, error: urlError } = await supabase.storage.from('avatars').getPublicUrl(filePath);
  if (urlError) throw urlError;

  if (isDevelopment) console.debug('Profile picture uploaded successfully:', publicUrl);
  return publicUrl;
};

// Fetch user collections (now with caching and fixed query)
export const fetchUserCollections = async (userId, context) => {
  try {
    if (isDevelopment) console.debug('Fetching user collections for user:', userId);
    const supabase = createClient(context);
    const { data, error } = await supabase
      .from('user_collections')
      .select('*')
      .eq('user_id', userId);

    if (error) throw error;

    if (isDevelopment) console.debug('User collections fetched successfully:', data);
    return data;
  } catch (error) {
    console.error('Error fetching user collections:', error);
    throw error;
  }
};

// Update user profile
export const updateUserProfile = async (userId, updates, context) => {
  const supabase = createClient(context);
  if (isDevelopment) {
    console.debug('Updating user profile for user:', userId);
    console.debug('Profile updates:', updates);
  }
  const { data, error } = await supabase.from('profiles').update(updates).eq('id', userId);

  if (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }

  if (isDevelopment) console.debug('User profile updated successfully:', data);
  return data;
};

// Sign up with email and password
export const signUpWithEmail = async (email, password, context) => {
  const supabase = createClient(context);
  const serviceRoleClient = createServiceRoleClient();

  try {
    if (isDevelopment) console.debug('Attempting to sign up with email:', email);

    // Sign up the user
    const { data: authData, error: authError } = await supabase.auth.signUp({ email, password });
    if (authError) throw authError;

    if (isDevelopment) console.debug('Sign up successful:', authData);

    // Create profile using service role client
    const { data: profileData, error: profileError } = await serviceRoleClient
      .from('profiles')
      .insert([{ id: authData.user.id }])
      .select()
      .single();

    if (profileError) {
      console.error('Error creating profile:', profileError);
      // If profile creation fails, we should delete the auth user to maintain consistency
      await serviceRoleClient.auth.admin.deleteUser(authData.user.id);
      throw new Error('Failed to create user profile. Please try again.');
    }

    if (isDevelopment) console.debug('Profile created successfully:', profileData);

    return { authData, profileData };
  } catch (error) {
    console.error('Error in signUpWithEmail:', error);
    throw new Error('An error occurred during sign-up. Please try again or contact support if the issue persists.');
  }
};

// Sign in with email and password
export const signInWithEmail = async (email, password, context) => {
  const supabase = createClient(context);

  try {
    if (isDevelopment) console.debug('Attempting to sign in with email:', email);

    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;

    if (isDevelopment) console.debug('Sign in successful:', data);

    return data;
  } catch (error) {
    console.error('Error in signInWithEmail:', error);
    throw new Error('An error occurred during sign-in. Please check your credentials and try again.');
  }
};

// Sign out and redirect to home page
export const signOut = async (context) => {
  const supabase = createClient(context);

  try {
    if (isDevelopment) console.debug('Attempting to sign out');
    const { error } = await supabase.auth.signOut();
    if (error) throw error;

    if (typeof window !== 'undefined') {
      window.location.href = '/';
    }
    if (isDevelopment) console.debug('Sign out successful');
  } catch (error) {
    console.error('Error in signOut:', error);
    throw new Error('An error occurred during sign-out. Please try again or contact support if the issue persists.');
  }
};

// Sign in with OAuth provider
export const signInWithOAuth = async (provider, options = {}, context) => {
  const supabase = createClient(context);

  try {
    if (isDevelopment) console.debug('Attempting to sign in with OAuth provider:', provider);

    const redirectTo = options.redirectTo || `${typeof window !== 'undefined' ? window.location.origin : ''}/auth/callback`;
    if (isDevelopment) console.debug('Redirect URL:', redirectTo);

    const scopes = provider === 'github' ? 'read:user user:email' : undefined;

    const state = Math.random().toString(36).substring(2, 15);
    if (typeof window !== 'undefined') {
      localStorage.setItem('oauthState', state);
    }

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo,
        scopes,
        skipBrowserRedirect: true,
        state,
      },
    });

    if (error) throw error;

    if (isDevelopment) console.debug('OAuth sign-in initiated successfully:', data);

    return data;
  } catch (error) {
    console.error('Error in signInWithOAuth:', error);
    throw new Error('An error occurred during OAuth sign-in. Please try again or contact support if the issue persists.');
  }
};

// Specific OAuth sign-in functions
export const signInWithGitHub = async (options = {}, context) => {
  if (isDevelopment) console.debug('Starting GitHub sign-in process');
  return signInWithOAuth('github', options, context);
};

export const signInWithGoogle = async (options = {}, context) => {
  if (isDevelopment) console.debug('Starting Google sign-in process');
  return signInWithOAuth('google', options, context);
};

// Refresh session using OAuth code
export const refreshSession = async (code, context) => {
  const supabase = createClient(context);

  try {
    if (isDevelopment) console.debug('Exchanging code for session');

    const { data, error } = await supabase.auth.exchangeCodeForSession(code);
    if (error) throw error;

    if (isDevelopment) console.debug('Session exchange successful:', data);

    return data;
  } catch (error) {
    console.error('Error in refreshSession:', error);
    throw new Error('An error occurred while refreshing the session. Please try logging in again.');
  }
};

// Check if the current session is valid
export const isSessionValid = async (context) => {
  const supabase = createClient(context);

  try {
    if (isDevelopment) console.debug('Checking session validity');
    const { data: { session } } = await supabase.auth.getSession();

    if (!session) {
      if (isDevelopment) console.debug('No active session found');
      return false;
    }

    const expiresAt = new Date(session.expires_at * 1000);
    const now = new Date();

    if ((expiresAt.getTime() - now.getTime()) / 1000 / 60 < 5) {
      if (isDevelopment) console.debug('Session about to expire, refreshing');
      await supabase.auth.refreshSession();
      if (isDevelopment) console.debug('Session refreshed successfully');
    }

    if (isDevelopment) console.debug('Session is valid');
    return true;
  } catch (error) {
    console.error('Error checking session validity:', error);
    return false;
  }
};

export default createClient;
