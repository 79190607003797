import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signUpWithEmail } from '../../supabaseClient';

function UserSignup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      setError('Valid email is required');
      return false;
    }
    if (!formData.password.trim() || formData.password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    if (!formData.firstName.trim() || !formData.lastName.trim()) {
      setError('First name and last name are required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const { data, error } = await signUpWithEmail(formData.email, formData.password, {
        data: {
          first_name: formData.firstName,
          last_name: formData.lastName
        }
      });

      if (error) {
        if (error.message.includes('User already registered')) {
          throw new Error('An account with this email already exists. Please try logging in.');
        }
        throw error;
      }

      console.log('Signup successful:', data);
      setSuccess(true);
      
      // Reset form
      setFormData({
        email: '',
        password: '',
        firstName: '',
        lastName: ''
      });

      // Redirect to login page after a short delay
      setTimeout(() => navigate('/login'), 5000);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'There was an error signing up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <section className="signup section" id="user-signup">
        <div className="container">
          <h2 className="glow-text">Thank You for Signing Up!</h2>
          <p>Your account has been created successfully. Please check your email for verification instructions.</p>
          <p>You will be redirected to the login page in a few seconds. If not, please <Link to="/login">click here to login</Link>.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="user-signup section" id="user-signup">
      <div className="container">
        <h2 className="glow-text">Create Your Account</h2>
        {error && <p className="error-message">{error}</p>}
        <form id="user-signup-form" className="neon-border" onSubmit={handleSubmit}>
          <div className="form-group">
            <input 
              type="text" 
              id="firstName" 
              name="firstName" 
              placeholder="First Name" 
              required 
              value={formData.firstName} 
              onChange={handleChange} 
            />
          </div>
          <div className="form-group">
            <input 
              type="text" 
              id="lastName" 
              name="lastName" 
              placeholder="Last Name" 
              required 
              value={formData.lastName} 
              onChange={handleChange} 
            />
          </div>
          <div className="form-group">
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="Your Email" 
              required 
              value={formData.email} 
              onChange={handleChange} 
            />
          </div>
          <div className="form-group">
            <input 
              type="password" 
              id="password" 
              name="password" 
              placeholder="Choose a Password" 
              required 
              value={formData.password} 
              onChange={handleChange} 
            />
          </div>
          <button type="submit" className="cta-button glow-button" disabled={loading}>
            {loading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>
        <p className="privacy-note">Already have an account? <Link to="/login">Login here</Link>.</p>
      </div>
    </section>
  );
}

export default UserSignup;