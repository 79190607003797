import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmail, signUpWithEmail, signInWithGitHub, signInWithGoogle } from '../../supabaseClient';
import { FaGithub, FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../AuthContext';

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    // Check password strength
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/\d/)) strength++;
    if (password.match(/[^a-zA-Z\d]/)) strength++;
    setPasswordStrength(strength);
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    setError('');
    try {
      if (isRegistering) {
        const { error } = await signUpWithEmail(email, password);
        if (error) throw error;
        alert('Registration successful! Please check your email to verify your account.');
      } else {
        const { error } = await signInWithEmail(email, password);
        if (error) throw error;
        login();
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleOAuthSignIn = async (provider) => {
    setError('');
    setLoading(true);
    try {
      const redirectTo = `${window.location.origin}/auth/callback`;
      console.log(`Initiating ${provider} sign-in with redirect URL:`, redirectTo);
      
      let result;
      if (provider === 'github') {
        result = await signInWithGitHub({ redirectTo });
      } else if (provider === 'google') {
        result = await signInWithGoogle({ redirectTo });
      } else {
        throw new Error(`Unsupported provider: ${provider}`);
      }

      console.log(`${provider} sign-in result:`, result);

      if (result.error) {
        throw result.error;
      }

      if (result.url) {
        console.log(`Redirecting to ${provider} OAuth URL:`, result.url);
        window.location.href = result.url;
      } else {
        console.error(`No URL returned from ${provider} sign-in`);
        setError(`Failed to initiate ${provider} sign-in. Please try again.`);
      }
    } catch (error) {
      console.error(`Error during ${provider} authentication:`, error);
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!email || !password) {
      setError('Please fill in all required fields.');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
      return false;
    }
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return false;
    }
    if (isRegistering && !name) {
      setError('Please enter your name.');
      return false;
    }
    return true;
  };

  const getErrorMessage = (error) => {
    console.error('Detailed error:', error);
    switch (error.message) {
      case 'Invalid login credentials':
        return 'Invalid email or password. Please try again.';
      case 'Email not confirmed':
        return 'Please verify your email address before logging in.';
      case 'User already registered':
        return 'An account with this email already exists. Please log in or use a different email.';
      default:
        return `An error occurred: ${error.message}`;
    }
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    marginBottom: '20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff',
    fontSize: '1rem'
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '140px',
    height: '50px',
    backgroundColor: '#24292e',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem'
  };

  return (
    <section className="user-login section" id="login">
      <div className="container">
        <h2 className="glow-text">{isRegistering ? 'Create an Account' : 'Login to Your Account'}</h2>
        <form onSubmit={handleSubmit} className="neon-border" style={{ maxWidth: '500px', margin: '0 auto', padding: '40px', backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '10px' }}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
              style={inputStyle}
              aria-label="Email"
            />
          </div>
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
              style={inputStyle}
              aria-label="Password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{ position: 'absolute', right: '10px', top: '50%', background: 'none', border: 'none', color: '#fff', cursor: 'pointer' }}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {password && (
            <div style={{ marginBottom: '20px' }}>
              <div style={{ height: '5px', backgroundColor: '#ddd', borderRadius: '5px' }}>
                <div
                  style={{
                    height: '100%',
                    width: `${passwordStrength * 25}%`,
                    backgroundColor: passwordStrength > 2 ? '#4CAF50' : passwordStrength > 1 ? '#FFC107' : '#F44336',
                    borderRadius: '5px',
                    transition: 'width 0.3s'
                  }}
                />
              </div>
              <p style={{ fontSize: '0.8rem', marginTop: '5px' }}>
                {passwordStrength === 0 && 'Very weak'}
                {passwordStrength === 1 && 'Weak'}
                {passwordStrength === 2 && 'Medium'}
                {passwordStrength === 3 && 'Strong'}
                {passwordStrength === 4 && 'Very strong'}
              </p>
            </div>
          )}
          {isRegistering && (
            <>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  disabled={loading}
                  style={inputStyle}
                  aria-label="Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  id="phone"
                  type="tel"
                  placeholder="Your Phone Number (Optional)"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={loading}
                  style={inputStyle}
                  aria-label="Phone Number"
                />
              </div>
            </>
          )}
          {error && <p className="error-message" style={{ color: 'red', marginBottom: '20px' }} role="alert">{error}</p>}
          <button type="submit" className="cta-button glow-button" disabled={loading} aria-busy={loading}>
            {loading ? 'Processing...' : (isRegistering ? 'Register' : 'Login')}
          </button>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <button 
              type="button"
              onClick={() => setIsRegistering(!isRegistering)} 
              style={{ background: 'none', border: 'none', color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}
            >
              {isRegistering ? 'Already have an account? Login' : 'New user? Register'}
            </button>
          </div>
        </form>
        <div className="sso-options" style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <button onClick={() => handleOAuthSignIn('github')} className="social-login-button github" style={buttonStyle} disabled={loading}>
            <FaGithub size={24} style={{ marginRight: '10px' }} /> GitHub
          </button>
          <button onClick={() => handleOAuthSignIn('google')} className="social-login-button google" style={{ ...buttonStyle, backgroundColor: '#4285F4' }} disabled={loading}>
            <FaGoogle size={24} style={{ marginRight: '10px' }} /> Google
          </button>
        </div>
      </div>
    </section>
  );
}

export default Login;