import React, { useState, lazy, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './MobileMenu.css';
import HeroHeader from './components/common/HeroHeader';
import Features from './components/common/Features';
import Benefits from './components/common/Benefits';
import Testimonials from './components/common/Testimonials';
import Roadmap from './components/common/Roadmap';
import Signup from './components/auth/Signup';
import Footer from './components/common/Footer';
import BetaToS from './components/common/BetaToS';
import AboutUs from './components/common/AboutUs';
import Contribute from './components/common/Contribute';
import PrivacyPolicy from './components/common/PrivacyPolicy';
import TermsOfService from './components/common/TermsOfService';
import Support from './components/Support';
import Login from './components/auth/Login';
import UserSignup from './components/auth/UserSignup';
import ApiServices from './components/common/ApiServices';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, useAuth } from './AuthContext';
import ErrorBoundary from './components/common/ErrorBoundary';
import { initParticles } from './utils/particles';
import { signOut, isSessionValid } from './supabaseClient';
import { logError } from './utils/errorLogging';
import { runDatabaseHealthCheck } from './utils/dbHealthCheck';

// Lazy-loaded components
const Dashboard = lazy(() => import('./components/Dashboard'));
const AssetManager = lazy(() => import('./components/asset/AssetManager'));
const UserProfile = lazy(() => import('./components/user/UserProfile'));
const AuthCallback = lazy(() => import('./components/auth/AuthCallback'));
const CreateCollection = lazy(() => import('./components/collection/CreateCollection'));
const LinkAccounts = lazy(() => import('./components/user/LinkAccounts'));
const CollectionList = lazy(() => import('./components/collection/CollectionList'));
const CollectionDetail = lazy(() => import('./components/collection/CollectionDetail'));
const HistoricMetalPrices = lazy(() => import('./components/price/HistoricMetalPrices'));
const AddAssetToCollection = lazy(() => import('./components/asset/AddAssetToCollection'));
const AssetDetails = lazy(() => import('./components/asset/AssetDetails'));
const QuickAddAsset = lazy(() => import('./components/asset/QuickAddAsset'));
const BulkEditAssets = lazy(() => import('./components/asset/BulkEditAssets'));
const AutoSuggestSearch = lazy(() => import('./components/AutoSuggestSearch'));
const AdvancedSearch = lazy(() => import('./components/AdvancedSearch'));
const VisualSearch = lazy(() => import('./components/asset/VisualSearch'));
const AssetCategoryBrowser = lazy(() => import('./components/asset/AssetCategoryBrowser'));
const AssetRecommendations = lazy(() => import('./components/asset/AssetRecommendations'));
const PriceTicker = lazy(() => import('./components/price/PriceTicker'));
const PublicCollectionBrowser = lazy(() => import('./components/collection/PublicCollectionBrowser'));

function AppContent() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showBetaToS, setShowBetaToS] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showContribute, setShowContribute] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [dbHealthCheckError, setDbHealthCheckError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, login, logout } = useAuth();
  const particlesInitialized = useRef(false);

  useEffect(() => {
    const handleGlobalError = (event) => {
      event.preventDefault();
      const errorMessage = event.error ? event.error.message : 'An unexpected error occurred';
      setGlobalError(errorMessage);
      logError(event.error || new Error(errorMessage));
    };

    window.addEventListener('error', handleGlobalError);
    return () => window.removeEventListener('error', handleGlobalError);
  }, []);

  useEffect(() => {
    const checkDatabaseHealth = async () => {
      try {
        const result = await runDatabaseHealthCheck();
        if (!result.healthy) {
          const errorMessage = `Database health check failed: ${result.errors.join(', ')}`;
          console.error(errorMessage);
          logError(new Error(errorMessage));
          setDbHealthCheckError(errorMessage);
        } else {
          setDbHealthCheckError(null);
        }
      } catch (error) {
        const errorMessage = `Error running database health check: ${error.message || 'Unknown error'}`;
        console.error(errorMessage);
        logError(new Error(errorMessage));
        setDbHealthCheckError(errorMessage);
      }
    };

    checkDatabaseHealth();
  }, []);

  useEffect(() => {
    const initializeParticles = () => {
      if (!particlesInitialized.current) {
        const particlesElement = document.getElementById('particles-js');
        if (particlesElement) {
          try {
            initParticles();
            particlesInitialized.current = true;
            console.log("Particles initialized successfully");
          } catch (error) {
            console.error("Error initializing particles:", error);
            logError(error);
          }
        }
      }
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const particlesElement = document.getElementById('particles-js');
          if (particlesElement) {
            initializeParticles();
            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const valid = await isSessionValid();
        if (valid) {
          login();
        } else {
          logout();
          // Only redirect to login if trying to access a protected route
          const protectedRoutes = ['/dashboard', '/asset-manager', '/profile', '/create-collection', '/historic-metal-prices'];
          if (protectedRoutes.includes(location.pathname)) {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        logError(error);
        setGlobalError('Unable to verify login status. Please try again later.');
      }
    };

    checkLoginStatus();
  }, [login, logout, navigate, location]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const openBetaToS = () => setShowBetaToS(true);
  const closeBetaToS = () => setShowBetaToS(false);
  const openAboutUs = () => setShowAboutUs(true);
  const closeAboutUs = () => setShowAboutUs(false);
  const openContribute = () => setShowContribute(true);
  const closeContribute = () => setShowContribute(false);
  const openPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const closePrivacyPolicy = () => setShowPrivacyPolicy(false);
  const openTermsOfService = () => setShowTermsOfService(true);
  const closeTermsOfService = () => setShowTermsOfService(false);
  const openSupport = () => setShowSupport(true);
  const closeSupport = () => setShowSupport(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      logError(error);
      setGlobalError('Unable to sign out. Please try again later.');
    }
  };

  const renderNavItems = (isMobile = false) => {
    const closeMenuIfMobile = isMobile ? toggleMobileMenu : () => {};
    const isLoginPage = location.pathname === '/login';

    if (isAuthenticated) {
      return (
        <>
          <li><Link to="/dashboard" onClick={closeMenuIfMobile}>Dashboard</Link></li>
          <li><Link to="/asset-manager" onClick={closeMenuIfMobile}>Asset Manager</Link></li>
          <li><Link to="/collections" onClick={closeMenuIfMobile}>Collections</Link></li>
          <li><Link to="/public-collections" onClick={closeMenuIfMobile}>Public Collections</Link></li>
          <li><Link to="/historic-metal-prices" onClick={closeMenuIfMobile}>Metal Prices</Link></li>
          <li><Link to="/profile" onClick={closeMenuIfMobile}>Profile</Link></li>
          <li><Link to="/" onClick={() => { handleSignOut(); closeMenuIfMobile(); }}>Logout</Link></li>
        </>
      );
    } else {
      return (
        <>
          <li><Link to="/" onClick={closeMenuIfMobile}>Home</Link></li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=features" onClick={closeMenuIfMobile}>Features</Link>
            ) : (
              <a href="#features" onClick={closeMenuIfMobile}>Features</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=benefits" onClick={closeMenuIfMobile}>Benefits</Link>
            ) : (
              <a href="#benefits" onClick={closeMenuIfMobile}>Benefits</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=testimonials" onClick={closeMenuIfMobile}>Testimonials</Link>
            ) : (
              <a href="#testimonials" onClick={closeMenuIfMobile}>Testimonials</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=roadmap" onClick={closeMenuIfMobile}>Roadmap</Link>
            ) : (
              <a href="#roadmap" onClick={closeMenuIfMobile}>Roadmap</a>
            )}
          </li>
          <li>
            {isLoginPage ? (
              <Link to="/?section=signup" onClick={closeMenuIfMobile}>Join Beta</Link>
            ) : (
              <a href="#signup" onClick={closeMenuIfMobile}>Join Beta</a>
            )}
          </li>
          <li><Link to="/login" onClick={closeMenuIfMobile}>Login</Link></li>
        </>
      );
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (globalError || dbHealthCheckError) {
    return (
      <div className="error-container">
        <h1>Oops! Something went wrong.</h1>
        {globalError && <p>{globalError}</p>}
        {dbHealthCheckError && (
          <div>
            <p>There was an issue with the database:</p>
            <p>{dbHealthCheckError}</p>
            <p>Please contact support if this issue persists.</p>
          </div>
        )}
        <button onClick={() => window.location.reload()}>Refresh Page</button>
      </div>
    );
  }

  return (
    <div className="App">
      <div id="particles-js"></div>
      <div className="navigation-wrapper">
        <nav className="main-nav">
          <div className="container">
            <ul className="desktop-menu">
              {renderNavItems()}
            </ul>
          </div>
        </nav>
        <div className={`hamburger-icon ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
          <ul>
            {renderNavItems(true)}
          </ul>
        </div>
      </div>
      
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={
              <>
                <HeroHeader />
                <Features />
                <Benefits />
                <Testimonials />
                <Roadmap />
                <Signup openBetaToS={openBetaToS} />
              </>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<UserSignup />} />
            <Route path="/api-services" element={<ApiServices />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/asset-manager" element={
              <ProtectedRoute>
                <AssetManager />
              </ProtectedRoute>
            } />
            <Route path="/profile" element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } />
            <Route path="/auth-callback" element={<AuthCallback />} />
            <Route path="/create-collection" element={
              <ProtectedRoute>
                <CreateCollection />
              </ProtectedRoute>
            } />
            <Route path="/link-accounts" element={
              <ProtectedRoute>
                <LinkAccounts />
              </ProtectedRoute>
            } />
            <Route path="/collections" element={
              <ProtectedRoute>
                <CollectionList />
              </ProtectedRoute>
            } />
            <Route path="/collections/:id" element={
              <ProtectedRoute>
                <CollectionDetail />
              </ProtectedRoute>
            } />
            <Route path="/public-collections" element={
              <ProtectedRoute>
                <PublicCollectionBrowser />
              </ProtectedRoute>
            } />
            <Route path="/historic-metal-prices" element={
              <ProtectedRoute>
                <HistoricMetalPrices />
              </ProtectedRoute>
            } />
            <Route path="/add-asset-to-collection/:collectionId" element={
              <ProtectedRoute>
                <AddAssetToCollection />
              </ProtectedRoute>
            } />
            <Route path="/asset-details/:assetId" element={
              <ProtectedRoute>
                <AssetDetails />
              </ProtectedRoute>
            } />
            <Route path="/quick-add-asset" element={
              <ProtectedRoute>
                <QuickAddAsset />
              </ProtectedRoute>
            } />
            <Route path="/bulk-edit-assets" element={
              <ProtectedRoute>
                <BulkEditAssets />
              </ProtectedRoute>
            } />
            <Route path="/auto-suggest-search" element={
              <ProtectedRoute>
                <AutoSuggestSearch />
              </ProtectedRoute>
            } />
            <Route path="/advanced-search" element={
              <ProtectedRoute>
                <AdvancedSearch />
              </ProtectedRoute>
            } />
            <Route path="/visual-search" element={
              <ProtectedRoute>
                <VisualSearch />
              </ProtectedRoute>
            } />
            <Route path="/asset-category-browser" element={
              <ProtectedRoute>
                <AssetCategoryBrowser />
              </ProtectedRoute>
            } />
            <Route path="/asset-recommendations" element={
              <ProtectedRoute>
                <AssetRecommendations />
              </ProtectedRoute>
            } />
            <Route path="/price-ticker" element={<PriceTicker />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>

      <Footer 
        openAboutUs={openAboutUs} 
        openContribute={openContribute} 
        openPrivacyPolicy={openPrivacyPolicy}
        openTermsOfService={openTermsOfService}
        openSupport={openSupport}
      />
      <BetaToS isOpen={showBetaToS} onClose={closeBetaToS} />
      <AboutUs isOpen={showAboutUs} onClose={closeAboutUs} />
      <Contribute isOpen={showContribute} onClose={closeContribute} />
      <PrivacyPolicy isOpen={showPrivacyPolicy} onClose={closePrivacyPolicy} />
      <TermsOfService isOpen={showTermsOfService} onClose={closeTermsOfService} />
      <Support isOpen={showSupport} onClose={closeSupport} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
