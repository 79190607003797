import { createClient } from '../supabaseClient';

const requiredTables = ['profiles', 'user_collections', 'saved_searches', 'public_assets', 'experience_logs'];
const requiredColumns = {
  profiles: ['id', 'experience', 'level'],
  user_collections: ['id', 'user_id', 'name', 'description', 'type', 'collection_type', 'start_year', 'end_year', 'is_public', 'total_coins', 'total_value', 'completeness', 'is_archived', 'version'],
  saved_searches: ['id', 'user_id', 'name', 'query', 'created_at', 'updated_at'],
  public_assets: ['id', 'collection_id', 'name', 'description', 'year', 'value'],
  experience_logs: ['id', 'user_id', 'experience_gained', 'reason', 'created_at']
};

export async function checkDatabaseHealth() {
  const supabase = createClient();
  const errors = [];

  try {
    // Check for required tables
    for (const table of requiredTables) {
      const { data, error } = await supabase
        .from(table)
        .select('id')
        .limit(1);

      if (error) {
        console.error(`Error checking table ${table}:`, error);
        errors.push(`Table '${table}' does not exist or is inaccessible.`);
      } else {
        console.log(`Table '${table}' exists and is accessible.`);
      }
    }

    // Check for required columns in tables
    for (const [table, columns] of Object.entries(requiredColumns)) {
      if (!errors.some(error => error.includes(table))) {
        for (const column of columns) {
          const { data, error } = await supabase
            .from(table)
            .select(column)
            .limit(1);

          if (error) {
            console.error(`Error checking column ${column} in ${table}:`, error);
            errors.push(`Column '${column}' does not exist in '${table}' table.`);
          } else {
            console.log(`Column '${column}' exists in '${table}' table.`);
          }
        }
      }
    }

    if (errors.length > 0) {
      console.error('Database health check failed:', errors);
      return { healthy: false, errors };
    }

    console.log('Database health check passed.');
    return { healthy: true };
  } catch (error) {
    console.error('Unexpected error during database health check:', error);
    return { healthy: false, errors: [error.message] };
  }
}

export async function runDatabaseHealthCheck() {
  try {
    const result = await checkDatabaseHealth();
    if (!result.healthy) {
      console.error('Database health check failed. Please ensure all required tables, columns, and constraints are created.');
      console.error('Errors:', result.errors);
    } else {
      console.log('Database health check passed successfully.');
    }
    return result;
  } catch (error) {
    console.error('Error running database health check:', error);
    return { healthy: false, errors: [error.message] };
  }
}