import React from 'react';
import './ApiServices.css';

function ApiServices() {
  const features = [
    { title: 'Real-time Data', description: 'Access up-to-the-minute market data and analytics.' },
    { title: 'Secure Integration', description: 'Bank-level security protocols to keep your data safe.' },
    { title: 'Customizable Endpoints', description: 'Tailor our API to fit your specific needs.' },
    { title: 'Comprehensive Documentation', description: 'Detailed guides and examples to get you started quickly.' },
  ];

  const pricingTiers = [
    {
      name: 'Basic',
      price: '$99/month',
      features: [
        '100 API calls/day',
        'Basic support',
        'Core endpoints',
      ],
    },
    {
      name: 'Pro',
      price: '$299/month',
      features: [
        '1000 API calls/day',
        'Priority support',
        'All endpoints',
        'Custom integration assistance',
      ],
    },
    {
      name: 'Enterprise',
      price: 'Custom pricing',
      features: [
        'Unlimited API calls',
        '24/7 dedicated support',
        'Custom endpoint development',
        'On-premise deployment options',
      ],
    },
  ];

  return (
    <section className="api-services section" id="api-services">
      <div className="container">
        <h2 className="glow-text">Our API Services</h2>
        <p className="section-description">Empower your applications with our robust and scalable API services.</p>
        
        <div className="api-features">
          {features.map((feature, index) => (
            <div key={index} className="feature">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
        
        <h3 className="pricing-title glow-text">Pricing Plans</h3>
        <div className="pricing-tiers">
          {pricingTiers.map((tier, index) => (
            <div key={index} className="tier neon-border">
              <h4>{tier.name}</h4>
              <p className="price">{tier.price}</p>
              <ul>
                {tier.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>{feature}</li>
                ))}
              </ul>
              <button className="cta-button glow-button">Buy Now</button>
            </div>
          ))}
        </div>
        
        <div className="cta-section">
          <h3>Ready to Get Started?</h3>
          <p>Contact our sales team for a demo or to discuss custom solutions.</p>
          <button className="cta-button glow-button">Contact Sales</button>
        </div>
      </div>
    </section>
  );
}

export default ApiServices;