import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../utils/errorLogging';
import './ErrorReporter.css';

const ErrorReporter = ({ error, errorInfo }) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the error report to your backend
    // For now, we'll just log it
    logError('User reported error', {
      error,
      errorInfo,
      userFeedback: feedback
    });
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return (
      <div className="error-reporter-success" role="status" aria-live="polite">
        <p>Thank you for your feedback. We'll look into this issue.</p>
      </div>
    );
  }

  return (
    <div className="error-reporter" role="complementary" aria-labelledby="error-reporter-title">
      <h3 id="error-reporter-title">Help us improve</h3>
      <p>We're sorry you encountered an error. Please tell us what happened:</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="error-feedback">What were you doing when the error occurred?</label>
          <textarea
            id="error-feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows={4}
            required
            aria-required="true"
          />
        </div>
        <button type="submit" aria-label="Submit feedback">Submit Feedback</button>
      </form>
    </div>
  );
};

ErrorReporter.propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.object
};

export default ErrorReporter;